<template>
    <div class="recommendBlogs">
        <h2>Blogs you might like</h2>
        <ul>
            <li v-for="(item,i) in recommended" :key="i" >
                <router-link :to="{name:'BlogDetail', params: {slug:item.slug} }" >
                    <div class="blogsBlock">
                        <img :src="item.image" class="img-fluid" alt="">
                        <h2>{{item.title}}</h2>
                        <div v-html="item.short_description"></div>
                        <span class="upload-date">{{ item.created_at }}</span>
                    </div>
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'RecommendedBlogs',
    props:{
        recommendedData:{
          required:true,
          type:[Array,Object],
        }
    },
    data() {
        return {
            recommended: this.recommendedData,
        };        
    },
    watch: {
        recommendedData(value) {
            let app = this;
            app.recommended =  value;
        },
    },
}
</script>