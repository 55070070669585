<template>
    <div>
        <section class="innerBanner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb">
                            <ul>
                                <li>
                                    <router-link to="">
                                        <span class="icon-home"></span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="">Blog</router-link>
                                </li>
                                <li>
                                    <router-link to="">{{title}}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pageWrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8">
                        <BlogContent :postDetail="postDetail" />
                    </div>
                    <div class="col-xl-4">
                        <RecommendedBlogs :recommendedData="recommendedData"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios"
import api from "@/services/api"
import Helper from "@/services/helper"
import BlogContent from '@/views/Blogs/partials/BlogContent'
import RecommendedBlogs from '@/views/Blogs/partials/RecommendedBlogs'
export default {
    name: 'BlogDetail',
    components: { BlogContent, RecommendedBlogs },
    data(){
        return {
            page_slug: this.$route.params.slug,
            title:'',
            postDetail: [],
            recommendedData: [],
        };
    },
    mounted() {
        let app = this;
        app.renderDetail();
        app.renderRecommended();
    },
    methods: {
        async renderDetail() {
            let app = this;
            Helper.showSpinner();
            await axios.get(api.getUrl("/post/" + app.page_slug )).then(function (response) {
                if (response.data.success) {
                    document.title = response.data.data.title;
                    app.title = response.data.data.title;
                    app.postDetail = response.data.data;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        async renderRecommended() {
            let app = this;
            await axios.get(api.getUrl("/post/recommended/" + app.page_slug )).then(function (response) {
                if (response.data.success) {
                    app.recommendedData = response.data.data;
                }
            });
        },
    },
    created() {
        let app = this;
        app.$watch(
        () => app.$route.query,
        () => {
            app.page_slug = app.$route.params.slug;
            app.renderDetail();
            app.renderRecommended();
        }
        );
    },
}
</script>
<style src="../../assets/css/blogs.css"></style>