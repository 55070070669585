<template>
    <div class="blogContent-wrap">
        <img :src="detail.image" class="img-fluid" alt="">
        <div class="blogTitle mb-3">
            <h1>{{detail.title}}</h1>
            <span class="upload-date">Posted On: {{ detail.created_at }}</span>
        </div>
        <div v-html="detail.full_description"></div>
    </div>
</template>
<script>
export default {
    name: 'BlogContent',
    props:{
        postDetail:{
          required:true,
          type:[Array,Object],
        }
    },
    data() {
        return {
            detail: this.postDetail,
        };        
    },
    watch: {
        postDetail(value) {
            let app = this;
            app.detail =  value;
        },
    },
}
</script>